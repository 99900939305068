<template>
  <v-card>
    <div>
      <img
        id="thumbnail"
        style="width: 300px; heigth: 100%; border: none"
        :src="src"
        class="grey lighten-2"
      />
    </div>
    <v-card-actions class="mt-0">
      <v-spacer />
      <pcc-button-tooltip
        v-on:click="cancel()"
        :icon="Icons.close"
        position="top"
        :tooltip="$t('commons.operation.cancel')"
        color="primary"
        textColor="primary"
      />
      <pcc-button-tooltip
        v-on:click="crop()"
        :icon="Icons.save"
        :loading="loading"
        position="top"
        :tooltip="$t('commons.operation.save')"
        color="primary"
        textColor="primary"
      />
    </v-card-actions>
  </v-card>
</template>
<script>
import HttpClient from '../../store/HttpClient';
import _ from 'lodash';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';
import PccButtonTooltip from '../Components/PccButtonTooltip';

export default {
  name: 'cropper-component',
  components: {
    'pcc-button-tooltip': PccButtonTooltip,
  },
  data: function () {
    return {
      file: null,
      fileName: null,
      loading: false,
      cropper: null,
      cropperObj: null,
    };
  },
  props: {
    src: {
      type: String,
      required: false,
      default: null,
    },
    accept: {
      type: String,
      required: false,
      default: 'image/*',
    },
  },
  methods: {
    initCropper() {
      let img = document.getElementById('thumbnail');
      let cropper = new Cropper(img, {
        checkCrossOrigin: false,
        checkOrientation: false,
        aspectRatio: 1 / 1,
        autoCropArea: 0.65,
        restore: false,
        guides: false,
        center: true,
        highlight: false,
        toggleDragModeOnDblclick: false,
        cropBoxMovable: true,
        cropBoxResizable: true,
      });
      this.cropperObj = cropper;
    },
    clearCropper() {
      try {
        this.cropperObj.destroy();
      } catch (error) {}
    },
    async crop() {
      try {
        let cropper = this.cropperObj.getCroppedCanvas();
        let canvas = document.createElement('canvas');
        canvas.appendChild(cropper);
        let ctx = canvas.getContext('2d');
        let width = cropper.width;
        let height = cropper.height;
        canvas.width = width;
        canvas.height = height;
        ctx.fillStyle = '#fff';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(cropper, 0, 0, width, height);
        const blob = await (await fetch(canvas.toDataURL())).blob('image/jpeg', 0.9);
        await new Promise((resolve, reject) => {
          var reader = new FileReader();
          reader.onloadend = () => {};
          reader.onerror = () => {
            resolve();
          };
          reader.readAsArrayBuffer(blob);
          this.fileName = `${new Date()}.jpeg`;
          this.file = blob;
          this.uppload();
        });
      } catch (error) {}
    },
    async uppload() {
      try {
        this.formData = new FormData();
        this.formData.append('file', this.file, this.fileName);
        if (this.formData) {
          this.loading = true;
          let result = await HttpClient.upploadFile(this.formData);
          this.cropperObj.destroy();
          this.$emit('input', result[0].location);
        }
      } catch (error) {
      } finally {
        this.dialog = false;
        this.loading = false;
      }
    },
    cancel() {
      this.$emit('cancel');
    },
  },
  mounted() {
    let img = document.getElementById('thumbnail');
    if (img) {
      this.initCropper();
    }
  },
};
</script>
<style scoped>
#thumbnail {
  display: block;
  max-width: 100%;
}
/* Ensure the size of the image fit the container perfectly */
img {
  display: block;
  /* This rule is very important, please don't ignore this */
  max-width: 100%;
}
</style>
